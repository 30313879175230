import store from '@/store'

import fileConf from '@/syslib/file_conf'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import {
  appServiceRequest,
  appVersionConfigRequest,
  appServiceConfigRequest
} from '@/api'

import {
  sysInstanceList
} from './sys_instance'

import {
  appServiceConfigList
} from './app_service_config'

import {
  appServiceAccessConfigList
} from './app_service_access_config'

import {
  appServiceUserList
} from './app_service_user'

import {
  serverList
} from './server'

import {
  appVersionList
} from './app_version'

export const appServiceList = function (parm = {}) {
  let config = {
    key: parm.key || 'app_service',
    titleConfig: {
      title: parm && parm.title ? parm.title : '服务',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add_deal',
        label: '新增',
        dealFun: async function (vm) {
          let formData = await vm.$form.open({
            oldData: vm.config.getHisData ? vm.config.getHisData(vm.dataParm) : {},
            formTitle: '服务',
            formParms: vm.config.updateConfig.getFormParms({}, 'add', vm.dataParm)
          })
          if (formData) {
            vm.loading = true
            let serviceData = await appServiceRequest.add(formData)
            let appVersionConfigData = await appVersionConfigRequest.get({
              appVersionId: serviceData.appVersionId
            })
            if (appVersionConfigData.length > 0) {
              let queue = []
              appVersionConfigData.forEach((v) => {
                queue.push(appServiceConfigRequest.add({
                  appServiceId: serviceData.id,
                  uploadPkgPath: v.uploadPkgPath,
                  uploadPkgName: v.uploadPkgName,
                  restartTypeId: v.restartTypeId,
                  config: v.config,
                  startTimeout: v.startTimeout,
                  stopTimeout: v.stopTimeout,
                  timeout: v.timeout
                }))
              })
              await Promise.all(queue)
            }
            vm.loading = false
            vm.loadData()
          }
        }
      }]
    },
    async init () {
      store.dispatch('loadServerList')
      store.dispatch('loadAppVersionList')
      store.dispatch('loadSysInstanceList')
      store.dispatch('loadRestartTypeList')
      store.dispatch('loadCmdTypeList')
      return {}
    },
    listConfig: {
      filterFun (data, parm) {
        return parm ? data.filter(v => (v.appName + v.appVersionNumber + v.serverName).includes(parm)) : data
      },
      listMenus: parm && parm.listMenus ? parm.listMenus : [
        // {
        //   key: 'task',
        //   label: '相关任务',
        //   dealFun (data, vm) {
        //     vm.$router.push({
        //       name: 'task', query: {
        //         type: 'app_service',
        //         id: data.id
        //       }
        //     })
        //   }
        // },
        {
          key: 'deploy',
          label: '部署管理',
          dealFun: (data) => {
            window.open('/#/work/deploy?appServiceId=' + data.id)
          }
        },
        {
          key: 'log',
          label: '操作记录',
          dealFun: (data) => {
            window.open('/#/server/db_operation_log?appServiceId=' + data.id)
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }
      ],
      config: appService(),
      getTitle: (data, dataParm) => {
        dataParm = dataParm || {}
        let strList = [data.appName]
        if (!dataParm.serverId) {
          strList.push(data.serverName)
        }
        return strList.join(' ')
      }
    },
    getHisData (dataParm) {
      return {
        sysInstanceId: dataParm ? dataParm.sysInstanceId : null
      }
    },
    async getData (dataParm) {
      return await appServiceRequest.get(dataParm)
    },
    updateConfig: {
      title: '服务',
      request: appServiceRequest,
      getFormParms (a, b, dataParm) {
        return [{
          type: 'select',
          label: '所属应用系统',
          key: 'sysInstanceId',
          selectDatas: store.getters.sysInstanceList.filter(v => dataParm && dataParm.sysInstanceId ? v.data.id === dataParm.sysInstanceId : true) || []
        },
        {
          type: 'select',
          label: '服务版本',
          key: 'appVersionId',
          selectDatas: store.getters.appVersionList.filter(v => dataParm && dataParm.appVersionId ? v.data.id === dataParm.appVersionId : true) || [],
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '运行服务器',
          key: 'serverId',
          selectDatas: store.getters.serverList.filter(v => dataParm && dataParm.serverId ? v.data.id === dataParm.serverId : true) || [],
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '访问地址',
          key: 'url'
        },
        {
          type: 'input',
          label: '端口',
          key: 'port'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'description'
        }]
      }
    }
  }
  return config
}

export const appService = function ({defaultOpen} = {}) {
  let config = {
    defaultOpen: defaultOpen || false,
    async getData (dataParm) {
      if (!dataParm || !dataParm.id) {
        return Promise.resolve({})
      }
      let data = await appServiceRequest.get({id: dataParm.id})
      return data.length > 0 ? data[0] : null
    },
    getTitle: (data) => {
      console.log(data)
      return data ? data.appName + ' ' + data.serverName : ''
    },
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '访问地址',
            field: 'url'
          },
          {
            label: '端口',
            field: 'port'
          },
          {
            label: '说明',
            field: 'description'
          }]
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.sysInstanceId || -1
        },
        config: sysInstanceList({title: '所属应用系统', titleMenus: [], listMenus: []})
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.appVersionId
        },
        config: appVersionList({title: '应用版本', titleMenus: [], listMenus: []})
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.serverId
        },
        config: serverList({title: '运行服务器', titleMenus: [], listMenus: [], noSearch: true})
      },
      {
        comp: TypeItem,
        dataParm: {
          appServiceId: data.id
        },
        config: appServiceUserList({}, {})
      },
      {
        comp: TypeItem,
        dataParm: {
          appServiceId: data.id
        },
        config: appServiceConfigList({}, {})
      },
      {
        comp: TypeItem,
        dataParm: {
          appServiceId: data.id
        },
        config: appServiceAccessConfigList({}, {})
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.appService ? fileConf.appService.getPath(data) : null
        }
      }]
    }
  }
  return config
}
