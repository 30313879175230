import TextInfo from '@/components/datainfo/TextInfo'

import {
  restartTypeRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '名称',
  key: 'name',
  check: {
    required: true
  }
},
{
  type: 'textarea',
  label: '启动方式',
  key: 'startInfo'
},
{
  type: 'textarea',
  label: '停止方式',
  key: 'stopInfo'
},
{
  type: 'textarea',
  label: '检查方式',
  key: 'checkInfo'
},
{
  type: 'textarea',
  label: '默认配置',
  key: 'defaultConfig'
}]

export const restartTypeList = function (parm = {}) {
  let config = {
    key: parm.key || 'request_type',
    titleConfig: {
      title: parm && parm.title ? parm.title : '服务启停类型',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: restartType(),
      getTitle: (data) => {
        return data.name
      }
    },
    async getData (dataParm) {
      let data = await restartTypeRequest.get({id: dataParm && dataParm.id ? dataParm.id : null})
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '服务启停类型',
      request: restartTypeRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  return config
}

export const restartType = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '名称',
            field: 'name'
          },
          {
            label: '启动方式',
            field: 'startInfo'
          },
          {
            label: '停止方式',
            field: 'stopInfo'
          },
          {
            label: '检查方式',
            field: 'checkInfo'
          },
          {
            label: '默认配置',
            field: 'defaultConfig'
          }]
        }
      }]
    }
  }
  return config
}
