import store from '@/store'

import TypeItem from '@/components/datainfo/TypeItem'

import {
  appVersionList
} from './app_version'

import {
  sysAppRequest
} from '@/api'

export const sysAppList = function (parm = {}) {
  let config = {
    key: parm.key || 'sys_app',
    titleConfig: {
      title: parm && parm.title ? parm.title : '应用',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadAppVersionList')
      return {}
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'del',
        label: '删除'
      }],
      config: sysApp(),
      getTitle: (data) => {
        return data.appName + data.appVersionNumber
      }
    },
    getHisData (dataParm) {
      return {
        sysVersionId: dataParm.sysVersionId
      }
    },
    async getData (dataParm) {
      return await sysAppRequest.get(dataParm)
    },
    updateConfig: {
      title: '应用',
      request: sysAppRequest,
      getFormParms () {
        return [{
          type: 'select',
          label: '应用版本',
          key: 'appVersionId',
          selectDatas: store.getters.appVersionList
        }]
      }
    }
  }
  return config
}

export const sysApp = function () {
  let config = {
    getInfoParm: (data) => {
      return [{
        comp: TypeItem,
        dataParm: {
          id: data.appVersionId
        },
        config: appVersionList()
      }]
    }
  }
  return config
}
