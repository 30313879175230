import TextInfo from '@/components/datainfo/TextInfo'

import store from '@/store'

import {
  appVersionSoftRequest
} from '@/api'

const getFormParms = function () {
  return [{
    type: 'select',
    label: '依赖软件',
    key: 'softId',
    selectDatas: store.getters.softList,
    check: {
      required: true
    }
  }]
}

export const appVersionSoftList = function (parm = {}) {
  let config = {
    key: parm.key || 'app_version_soft',
    titleConfig: {
      title: parm && parm.title ? parm.title : '依赖软件',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadSoftList')
      return {}
    },
    getHisData (dataParm) {
      return {
        appVersionId: dataParm.appVersionId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: appVersionSoft(),
      getTitle: (data) => {
        return parm && parm.getTitle ? parm.getTitle(data) : data.softName + ' ' + data.softVersion
      }
    },
    async getData (dataParm) {
      if (!dataParm || !dataParm.appVersionId) {
        return Promise.resolve([])
      }
      return await appVersionSoftRequest.get(dataParm)
    },
    updateConfig: {
      title: '依赖软件',
      request: appVersionSoftRequest,
      getFormParms
    }
  }
  return config
}

export const appVersionSoft = function () {
  let config = {
    getInfoParm () {
      let parm = [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '软件名',
            field: 'softName'
          },
          {
            label: '版本',
            field: 'softVersion'
          }]
        }
      }]
      return parm
    }
  }
  return config
}
