<template>
  <div class="base-info-item" v-if="data && config.infoParm.length > 0">
    <div class="info-item info-item-f" :key="item.field" v-for="item in config.infoParm">
      <div class="info-label" v-if="item.label">{{item.label}}:</div>
      <div class="info-value">
        <span>{{data[item.field]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoTextInfo',
  props: {
    data: {
      type: Object
    },
    config: {
      type: Object
    }
  }
}
</script>

<style scoped lang="less">
.info-block {
  width: 100%;
}
.info-item {
  display: flex;
  flex-direction: column;
  min-width: 13rem;
  background: #000;
}
.hover-show-btn {
  display: none;
}
.info-item-file:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.info-label:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.base-info-item {
  min-height: 3rem;
  margin-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  .info-item {
    background: rgba(255,255,255,0.05);
    line-height: 1.5rem;
    margin: 0.5rem 2rem 0.5rem 0;
    border-radius: 0.5rem;
    .info-label {
      margin-right: 0.2rem;
      font-size: 0.9rem;
    }
  }
}
.info-label {
  white-space:nowrap;
  color: #777;
  font-size: 0.9rem;
  display: flex;
  margin-right: 2rem;
}
.info-value {
  color: #333;
  white-space:wrap;
  overflow: hidden;
  text-overflow:ellipsis;
  font-size: 0.9rem;
}
.info-item-f {
  flex-direction: row !important;
}
</style>
