import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import fileConf from '@/syslib/file_conf'

import {
  sysAppList
} from './sys_app'

import {
  sysInstanceList
} from './sys_instance'

import store from '@/store'

import {
  sysVersionRequest
} from '@/api'


export const sysVersionList = function (parm = {}) {
  let config = {
    key: parm.key || 'sys_version',
    titleConfig: {
      title: parm && parm.title ? parm.title : '系统版本',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadSysVersionList')
      return {}
    },
    getHisData (dataParm) {
      return {
        sysId: dataParm.sysId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [
        {
          key: 'task',
          label: '相关任务',
          dealFun (data, vm) {
            vm.$router.push({
              name: 'task', query: {
                type: 'sys_version',
                id: data.id
              }
            })
          }
        },
        {
          key: 'sys_deploy',
          label: '系统部署',
          dealFun: (data) => {
            window.open('/#/work/sys_deploy?sysVersionId=' + data.id)
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }
      ],
      config: sysVersion(),
      getTitle: (data) => {
        return parm && parm.getTitle ? parm.getTitle(data) : data.versionNumber + ' ' + data.status
      }
    },
    async getData (dataParm) {
      if (!dataParm || !(dataParm.id || dataParm.sysId)) {
        return Promise.resolve([])
      }
      let data = await sysVersionRequest.get(dataParm)
      store.dispatch('loadSysVersionList', true)
      return data
    },
    updateConfig: {
      title: '系统版本',
      request: sysVersionRequest,
      getFormParms (o, type, dataParm) {
        return [{
          type: 'input',
          label: '版本号',
          key: 'versionNumber',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '继承版本',
          key: 'extendId',
          selectDatas: store.getters.sysVersionList.filter(v => dataParm && dataParm.sysId ? v.data.sysId === dataParm.sysId : true),
          fmDisabled: type === 'edit'
        },
        {
          type: 'datetimePicker',
          label: '发布时间',
          key: 'releaseTime'
        },
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: store.getters.versionStatusList,
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'description'
        }]
      }
    }
  }
  return config
}

export const sysVersion = function (parm) {
  let config = {
    defaultOpen: parm && parm.defaultOpen,
    async getData (dataParm) {
      if (!dataParm || !dataParm.id) {
        return Promise.resolve({})
      }
      let data = await sysVersionRequest.get({id: dataParm.id})
      return data.length > 0 ? data[0] : null
    },
    getTitle: (data) => {
      return parm && parm.getTitle ? parm.getTitle(data) : (data ? (data.versionNumber + ' ' + data.status) : '')
    },
    async getInfoParm (data) {
      let parm = [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '状态',
            field: 'status'
          },
          {
            label: '发布时间',
            field: 'releaseTime'
          },
          {
            label: '版本说明',
            field: 'description'
          }]
        }
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.sysVersion ? fileConf.sysVersion.getPath(data) : null
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          sysId: data.sysId,
          sysVersionId: data.id
        },
        config: sysAppList()
      },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     sysVersionId: data.id
      //   },
      //   config: sysModuleList()
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     sysVersionId: data.id
      //   },
      //   config: sysDemandGroupList()
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     id: data.extendId
      //   },
      //   config: sysVersionList({title: '继承版本', titleMenus: [], listMenus: []})
      // },
      {
        comp: TypeItem,
        dataParm: {
          sysId: data.sysId,
          sysVersionId: data.id
        },
        config: sysInstanceList()
      }]
      return parm
    }
  }
  return config
}
