import TypeItem from '@/components/datainfo/TypeItem'

import {
  appServiceAccessConfigRequest,
  appServiceUserRequest
} from '@/api'

import {
  appServiceUserList
} from './app_service_user'

export const appServiceAccessConfigList = function (parm = {}) {
  let config = {
    key: parm.key || 'app_service_access_config',
    titleConfig: {
      title: parm && parm.title ? parm.title : '连接配置',
      getTitleMenus: (dataList) => {
        let data = []
        if (parm && parm.titleMenus) {
          data = parm.titleMenus
        } else if (dataList && dataList.length === 0) {
          data = [{
            key: 'add',
            label: '新增'
          }]
        }
        return data
      }
    },
    async init (dataParm) {
      let appServiceUserList = await appServiceUserRequest.get(dataParm)
      appServiceUserList = appServiceUserList.map((v) => {
        return {
          key: v.id,
          label: v.user
        }
      })
      return {appServiceUserList}
    },
    listConfig: {
      getListMenus: () => {
        let data = [{
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
        if (parm && parm.listMenus) {
          data = parm.listMenus
        }
        return data
      },
      config: appServiceAccessConfig(parm.childParm || {}),
      getTitle: (data) => {
        return data.appServiceUserName
      }
    },
    getHisData (dataParm) {
      return {
        appServiceId: dataParm.appServiceId
      }
    },
    async getData (dataParm) { 
      if (!dataParm.appServiceId && !dataParm.id) {
        return Promise.resolve([])
      }
      return await appServiceAccessConfigRequest.get(dataParm)
    },
    updateConfig: {
      title: '连接配置',
      request: appServiceAccessConfigRequest,
      getFormParms (otherData) {
        return [{
          type: 'select',
          label: '连接用户',
          key: 'appServiceUserId',
          selectDatas: otherData.appServiceUserList,
          check: {
            required: true
          }
        }]
      }
    }
  }
  return config
}

export const appServiceAccessConfig = function ({defaultOpen, showCheckbox}) {
  let config = {
    showCheckbox: showCheckbox || false,
    defaultOpen: defaultOpen || false,
    getInfoParm: (data) => {
      return [{
        comp: TypeItem,
        dataParm: {
          id: (data.appServiceUserId || -1)
        },
        config: appServiceUserList({
          title: '连接用户',
          titleMenus: [],
          listMenus: []
        })
      }]
    }
  }
  return config
}
