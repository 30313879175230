<template>
  <div class="type-item" v-loadingx="loading"
    :class="{'type-item-lr': openConf.position === 'left', 'type-item-tb': openConf.position === 'bottom'}">
    <template v-if="openConf.position === 'left'">
      <div class="type-item-left">
        <div class="type-item-title">
          <fm-input-new placeholder="请输入"
            style="border-radius: 0;width: 14rem;" v-model="filterData">
            <em class="iconfont icon-search" style="color:rgba(0,0,0,0.25);margin-right: 0.3rem;" slot="suffix"></em>
          </fm-input-new>
        </div>
        <div class="type-item-data-title-list">
          <data-title
            v-for="(item, index) in showDataList"
            direction="row"
            :key="index"
            :title="config.listConfig.getTitle(item, dataParm)"
            @choose="chooseData = item"
            :active="chooseData && item.id === chooseData.id"
          ></data-title>
        </div>
        <div class="type-item-menu">
          <fm-btn
            v-for="item in config.titleConfig.getTitleMenus ? config.titleConfig.getTitleMenus(dataList) : config.titleConfig.titleMenus"
            :key="item.key"
            @click="clickTitleMenu(item)">{{item.label}}
          </fm-btn>
        </div>
      </div>
      <div class="type-item-right">
        <data-item-detail
          v-if="chooseData"
          :title="config.listConfig.getTitle(chooseData, dataParm)"
          :list-menus="config.listConfig.getListMenus ? config.listConfig.getListMenus(dataList) : config.listConfig.listMenus"
          @clickListMenu="clickListMenu"
          :data="chooseData"
          :direction="openConf.position === 'left' ? 'row' : 'column'"
          :config="config.listConfig.config">
        </data-item-detail>
      </div>
    </template>
    <template v-if="openConf.position === 'bottom'">
      <div class="type-item-title">
        <div class="title-text">{{config.titleConfig.title}}</div>
        <div class="title-menu">
          <fm-btn
            v-for="item in config.titleConfig.getTitleMenus ? config.titleConfig.getTitleMenus(dataList) : config.titleConfig.titleMenus"
            :key="item.key"
            class="hover-show-btn"
            size="mini"
            @click="clickTitleMenu(item)">{{item.label}}
          </fm-btn>
        </div>
      </div>
      <div class="type-item-data-list">
        <div class="type-item-data-item" v-for="item in showDataList" :key="item.id">
          <data-item
            :title="config.listConfig.getTitle(item, dataParm)"
            :list-menus="config.listConfig.getListMenus ? config.listConfig.getListMenus(dataList) : config.listConfig.listMenus"
            @clickListMenu="clickListMenu"
            :data="item"
            @checked="checked"
            :direction="openConf.position === 'left' ? 'row' : 'column'"
            :config="config.listConfig.config">
          </data-item>
        </div>
      </div>
    </template>
    <data-update
      :title-text="config.updateConfig.title"
      :open-dialog.sync="openDialogEdit"
      :open-dialog-del.sync="openDialogDel"
      :request="config.updateConfig.request"
      :deal-parm="config.updateConfig.dealParm"
      :his-data="hisData"
      @updateOver="loadData"
      @handleClose="openDialogEdit = false;openDialogDel = false"
      :form-parms="formParms">
    </data-update>
  </div>
</template>

<script>
import DataItem from './DataItem'
import DataTitle from './DataTitle'
import DataItemDetail from './DataItemDetail'
export default {
  name: 'TypeItem',
  inject: {
    parentLevel: {
      default: []
    }
  },
  components: {
    DataItem,
    DataTitle,
    DataItemDetail
  },
  props: {
    dataParm: {
      type: Object
    },
    config: {
      type: Object
    }
  },
  async created () {
    this.openConf = this.config.openConf ||  this.openConf
    this.otherData = this.config.init ? await this.config.init(this.dataParm) : {}
    this.loadData()
  },
  watch: {
    dataParm () {
      this.loadData()
    }
  },
  data () {
    return {
      level: [],
      openConf: {
        position: 'bottom',
        isPop: false
      },
      chooseData: null,
      openDetail: false,
      loading: false,
      dataList: [],
      otherData: {},
      formParms: [],
      hisData: null,
      openDialogEdit: false,
      openDialogDel: false,
      openDialogFile: false,
      filterData: null
    }
  },
  provide () {
    if (!this.parentLevel) {
      this.level = [this.config.key || 'no_key']
    } else {
      this.level = this.parentLevel.concat([this.config.key || 'no_key'])
    }
    return {
      parentLevel: this.level
    }
  },
  computed: {
    showDataList () {
      let data = this.dataList
      if (this.config.listConfig.filterFun) {
        data = this.config.listConfig.filterFun(data, this.filterData)
      }
      return data
    }
  },
  methods: {
    clickData (data) {
      this.chooseData = data
    },
    handleClose () {
      this.openDetail = false
    },
    async clickTitleMenu (i) {
      let key = i.key
      if (key === 'add') {
        this.hisData = this.config.getHisData ? this.config.getHisData(this.dataParm) : {}
        let data = this.config.updateConfig.getFormParms(this.otherData, 'add', this.dataParm)
        if (data && data.then) {
          this.formParms = await data
        } else {
          this.formParms = data
        }
        this.openDialogEdit = true
      } else if (i.dealFun) {
        await i.dealFun(this)
      }
    },
    async clickListMenu (i, data) {
      this.hisData = data
      let key = i.key
      if (key === 'edit') {
        let data1 = this.config.updateConfig.getFormParms(this.otherData, 'edit')
        if (data1 && data1.then) {
          this.formParms = await data1
        } else {
          this.formParms = data1
        }
        this.openDialogEdit = true
      } else if (key === 'del') {
        this.openDialogDel = true
      } else if (i.dealFun) {
        await i.dealFun(data, this)
      }
    },
    async loadData () {
      this.dataList = await this.config.getData(this.dataParm)
      this.$emit('dataChange', this.dataList.map(v => v))
    },
    checked (data) {
      this.$emit('checked', data)
    }
  }
}
</script>

<style lang="less" scoped>
@left-c-width: 18rem;
.title-text {
  white-space:nowrap;
}
.type-item {
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.type-item-tb {
  .type-item-title {
    display: flex;
    padding: 0 1.8rem;
    font-size: 1.1rem;
    height: 3rem;
    justify-content: space-between;
    align-items: center;
    color: #2F5BEA;
  }
  .type-item-data-list {
    height: calc(100% - 3rem);
    max-height: calc(100% - 3rem);
    overflow: auto;
  }
}
.type-item-lr {
  display: flex;
}
.title-menu {
  display: none;
}
.type-item-title:hover {
  .title-menu {
    display: block;
  }
}

.type-item-data-list::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}
.type-item-data-item {
  margin-bottom: 10px;
}

.type-item-left {
  width: @left-c-width;
  max-width: @left-c-width;
  min-width: @left-c-width;
  height: 100%;
  .type-item-title {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .fm-input-new {
      background: #fff;
    }
  }
  .type-item-data-title-list {
    max-height: calc(100% - 9rem);
    overflow-y: auto;
  }
  .type-item-menu {
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .fm-btn {
      width: calc(100% - 4rem);
      height: 2.2rem;
      border-radius: 0;
    }
  }
}
.type-item-right {
  width: calc(100% - @left-c-width);
  max-width: calc(100% - @left-c-width);
  min-width: calc(100% - @left-c-width);
  height: 100%;
  background: #fff;
}
</style>