import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'

import {
  rsaHelper,
  dateOperating

} from '@/fmlib'

import {
  serverUserRequest,
  workSysPublicKeyRequest,
  userPasswordRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '用户',
  key: 'username'
},
{
  type: 'datetimePicker',
  label: '最近密码更新时间',
  key: 'passwordUpdateTime'
},
{
  type: 'select',
  label: '所属用户',
  key: 'userId',
  selectDatas: []
},
{
  type: 'select',
  label: '登录方式',
  key: 'authType',
  selectDatas: [{key: 'rsa', label: 'rsa'}, {key: 'password', label: 'password'}]
},
{
  type: 'input',
  label: '密码',
  key: 'password'
},
{
  type: 'textarea',
  label: '公匙',
  key: 'pubKey'
},
{
  type: 'textarea',
  label: '说明',
  key: 'description'
}]

export const serverUserList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_user',
    titleConfig: {
      title: parm && parm.title ? parm.title : '用户',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadUserList')
      return {}
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      },
      {
        key: 'editPassword',
        label: '修改密码',
        dealFun: async function (data, vm) {
          const result = await vm.$dialog.confirm({title: '输入新密码', content: '确定', showInput: true})
          if (result) {
            vm.loading = true
            let pubKey = await workSysPublicKeyRequest.get()
            let newPassword = rsaHelper.rsaEncrypt(pubKey, result)
            await serverUserRequest.update(data.id, Object.assign({} , data, {
              passwordUpdateTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
              password: newPassword
            }))
            vm.loading = false
            vm.$notify({
              title: '系统提示',
              message: '服务器用户 (' + data.username + ') 密码修改成功',
              type: 'seccess'
            })
          }
        }
      },
      {
        key: 'getPassword',
        label: '查看密码',
        dealFun: async function (data, vm) {
          let rsaKey = await rsaHelper.genterRsaKey()
          let parm = {
            serverUserId: data.id,
            publicKey: rsaKey.pubkey
          }
          let pwd = await userPasswordRequest.getServer(parm)
          // let pwd = rsaHelper.rsaEncrypt(pbuKeyStr, 'asdasdasd').cipher
          pwd = rsaHelper.rsaDecrypt(rsaKey.prikey, pwd)
          vm.$dialog.confirm({title: '系统提示', content: '密码: ' + pwd})
        }
      }],
      config: serverUser(),
      getTitle: (data) => {
        return data.username
      }
    },
    async getData (dataParm) {
      return await serverUserRequest.get(dataParm)
    },
    updateConfig: {
      title: '用户',
      request: serverUserRequest,
      dealParm: async (data, p) => {
        if (p && p.type === 'add' && data.password) {
          let pubKey = await workSysPublicKeyRequest.get()
          data.password = rsaHelper.rsaEncrypt(pubKey, data.password)
          return data
        } else {
          return Promise.resolve(data)
        }
      },
      getFormParms (a, type) {
        formParms[2].selectDatas = store.getters.userList
        return type === 'edit' ? formParms.filter(v => !['pubKey', 'password'].includes(v.key)) : formParms
      }
    }
  }
  return config
}

export const serverUser = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.filter(v => v.key !== 'password').map((v) => {
            return {
              label: v.label,
              field: v.key === 'userId' ? 'userName' : v.key
            }
          })
        }
      }]
    }
  }
  return config
}
