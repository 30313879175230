<template>
  <div class="info-node-dev">
    <div class="data-tiem-title" ref="dataItemTitle">
      <data-title
        :title="showTitle"
        @choose="choose"
        :active="show % 2 === 1"
        :menus="useListMenus"
        @clickMenu="clickListMenu"
        :show-checkbox="config.showCheckbox"
        @checked="checked"
        @dbclick="openDetailPage"
      ></data-title>
    </div>
    <fm-poper v-model="openDetail" position="right-start">
      <div class="data-tiem-detail-pop" :style="poperStyle">
        <data-item-detail
          style="background-color: rgba(47, 91, 234, 0.1);"
          v-if="openDetail"
          :title="showTitle"
          :data="showData"
          :config="config">
        </data-item-detail>
      </div>
    </fm-poper>
    <div class="data-tiem-detail" v-if="show && !isPop" v-show="show % 2 === 1">
      <data-item-detail
        :title="showTitle"
        :data="showData"
        :config="config">
      </data-item-detail>
    </div>
    <fm-modal
      v-model="openDetailModel"
      width="90%"
      v-if="openDetailModel">
      <data-item-detail
        :title="showTitle"
        direction="row"
        :data="showData"
        :config="config">
      </data-item-detail>
    </fm-modal>
  </div>
</template>

<script>
import DataTitle from './DataTitle'
import DataItemDetail from './DataItemDetail'
export default {
  name: 'DataItem',
  inject: {
    parentLevel: {
      default: []
    }
  },
  provide () {
    return {
      parentLevel: this.parentLevel
    }
  },
  components: {
    DataTitle,
    DataItemDetail
  },
  data () {
    return {
      level: [],
      infoParm: [],
      show: 0,
      openDetail: false,
      openDetailModel: false,
      direction: 'column',
      showData: null,
      poperStyle: null
    }
  },
  watch: {
    showData () {
      this.infoParm = []
      if (this.showData) {
        let vm = this
        this.$nextTick(async function () {
          let data = vm.config.getInfoParm(vm.showData)
          if (data && data.then) {
            vm.infoParm = await data
          } else {
            vm.infoParm = data
          }
        })
      }
    },
    data () {
      this.showData = this.data
    }
  },
  async created () {
    if (this.data) {
      this.showData = this.data
    } else if (this.config.getData && this.dataParm) {
      this.showData = await this.config.getData(this.dataParm)
    }
    if (this.showType === 'card') {
      this.direction = this.config.direction || 'row'
    }
    this.show = this.config.defaultOpen ? 1 : 0
    if (this.showType === 'card') {
      this.show = 1
    }
    let data = this.config.getInfoParm(this.showData)
    if (data && data.then) {
      this.infoParm = await data
    } else {
      this.infoParm = data
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    // card line
    showType: {
      type: String,
      default: 'line'
    },
    listMenus: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object
    },
    dataParm: {
      type: Object
    },
    config: {
      type: Object
    }
  },
  computed: {
    isPop () {
      return this.$store.getters.isPop
    },
    useListMenus () {
      return this.listMenus
    },
    showTitle () {
      return this.title || (this.config.getTitle ? this.config.getTitle(this.showData) : '')
    }
  },
  methods: {
    openDetailPage () {
      this.openDetailModel = true
      // if (this.showData && this.parentLevel.length > 0) {
      //   window.open('/#/work/detail?id=' + this.showData.id + '&type=' + this.parentLevel[this.parentLevel.length - 1])
      // }
    },
    checked (data) {
      this.$emit('checked', {
        data: this.showData,
        checked: data
      })
    },
    choose () {
      this.show += 1
      if (this.isPop && this.show % 2 === 1) {
        window.cltest = this.$refs.dataItemTitle
        this.poperStyle = {'max-height': (window.innerHeight - this.$refs.dataItemTitle.getBoundingClientRect().top) - 10 + 'px'}
        this.openDetail = true
        this.$parent.$children.forEach(cmp => {
          if (cmp !== this) {
            cmp.openDetail = false
            cmp.show = 0
          }
        })
      } else {
        this.openDetail = false
      }
    },
    clickListMenu (itemM) {
      this.$emit('clickListMenu', itemM, this.showData)
    }
  }
}
</script>

<style lang="less" scoped>
.data-tiem-detail-pop {
  margin-left: -10px;
  background: #FFF;
  max-width: 18rem;
  overflow-y: auto;
}
.data-tiem-detail {
  padding-left: 1.4rem;
}
.page-info-node-content-item {
  border-right: 1px solid #dcdee2;
}
.info-title-card {
  line-height: 2rem !important;
}
.info-node-text-card {
   margin-left: 1rem;
}
.page-info-node-content-card-column {
  overflow-y: auto;
}
.last-page-info-node-content-item-card-row {
  border-right: 0px dashed #dcdee2;
  margin-right: 0;
}
.node-card-row {
  flex: 1;
  width: calc(100% - 1rem);
  margin-left: 3px;
  padding-left: 3px;
}
.page-info-node-content-model {
  border-left: 1px solid #dcdee2;
  border-right: 1px solid #dcdee2;
  border-bottom: 1px solid #dcdee2;
  height: 50vh;
  max-height: 50vh;
}
.page-info-node-content {
  border-top: 1px solid #dcdee2;
  display: flex;
  width: 100%;
  overflow-x: auto;
}
.info-node-text {
  color: #1890FF;
}
.hover-show-btn {
  position: absolute;
  right: 0;
  bottom: 1rem;
  padding: 0 1rem;
  border-radius: 0.3rem;
  z-index: 2;
  display: flex;
  align-items: center;
}
.info-title {
  position: relative;
  font-size: 1.3rem;
  line-height: 1;
  padding: 1rem 1rem 0.5rem 1rem;
  .df-i {
    margin-right: 1rem;
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 0.3rem;
    transform:rotate(90deg);
    transition: 0.3s;
    color: #333;
  }
  .left-arrow {
    transform:rotate(180deg);
  }
}
.info-node-dev {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page-info-node-content {
  flex: 1;
}
</style>