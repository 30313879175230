import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import {
  sysVersionList
} from './sys_version'

import {
  appServiceList
} from './app_service'

import {
  sysInstanceUserList
} from './sys_instance_user'

import fileConf from '@/syslib/file_conf'

// import {
//   sysInstanceMaintainRecordList
// } from './sys_instance_maintain_record'

import {
  sysInstanceRequest,
  // sysModuleRequest,
  // sysFeaturesRequest,
} from '@/api'

export const sysInstanceList = function (parm = {}) {
  let config = {
    key: parm.key || 'sys_instance',
    titleConfig: {
      title: parm && parm.title ? parm.title : '应用系统',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadSysList')
      store.dispatch('loadSysVersionList')
      store.dispatch('loadOrgList')
      return {}
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: sysInstance(),
      getTitle: (data) => {
        return (data.userOrgName || data.user) + ' ' + data.sysName + ' ' + data.type
      }
    },
    async getData (dataParm) {
      dataParm = dataParm || {}
      let data = await sysInstanceRequest.get({
        sysVersionId: dataParm.sysVersionId,
        sysId: dataParm.sysId,
        useOrgId: dataParm.useOrgId,
        id: dataParm.id
      })
      return data
    },
    updateConfig: {
      title: '应用系统',
      request: sysInstanceRequest,
      async getFormParms (a, b, dataParm) {
        return [{
          type: 'select',
          label: '使用方',
          key: 'useOrgId',
          selectDatas: store.getters.orgList,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '名称',
          key: 'user',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '类型',
          key: 'type',
          selectDatas: [{'key': '正式环境', 'label': '正式环境'}, {'key': '测试环境', 'label': '测试环境'}, {'key': '开发环境', 'label': '开发环境'}, {'key': 'DEMO', 'label': 'DEMO'}, {'key': '其他', 'label': '其他'}]
        },
        {
          type: 'select',
          label: '所属系统',
          key: 'sysId',
          selectDatas: store.getters.sysList.filter(v => dataParm && dataParm.sysId ? v.data.id === dataParm.sysId : true)
        },
        {
          type: 'select',
          label: '所属系统版本',
          key: 'sysVersionId',
          getSelectDatas: (data) => {
            return store.getters.sysVersionList.filter(v => data && v.data.sysId === data.sysId && (dataParm && dataParm.sysVersionId ? v.data.id === dataParm.sysVersionId : true))
          }
        },
        {
          type: 'datetimePicker',
          label: '上线时间',
          key: 'startTime'
        },
        {
          type: 'datetimePicker',
          label: '上次更新时间',
          key: 'endTime'
        },
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: [{'key': '正常', 'label': '正常'}, {'key': '异常', 'label': '异常'}, {'key': '已下线', 'label': '已下线'}]
        },
        {
          type: 'input',
          label: '访问地址',
          key: 'url'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'description'
        }]
      }
    }
  }
  if (parm.openConf) {
    config.openConf = parm.openConf
  }
  return config
}

export const sysInstance = function ({defaultOpen, showCheckbox, notShowInfo} = {}) {
  let config = {
    showCheckbox: showCheckbox || false,
    defaultOpen: defaultOpen || false,
    async getData (dataParm) {
      dataParm = dataParm || {}
      let data = await sysInstanceRequest.get({
        sysVersionId: dataParm.sysVersionId,
        sysId: dataParm.sysId,
        id: dataParm.id
      })
      return data.length > 0 ? data[0] : null
    },
    async getInfoParm (data) {
      // let moduleList = await sysModuleRequest.get({sysVersionId: data.sysVersionId})
      // moduleList = moduleList.map((v) => {
      //   return {
      //     key: v.sourceId,
      //     label: v.name
      //   }
      // })
      // let featuresList = await sysFeaturesRequest.get({sysVersionId: data.sysVersionId})
      // featuresList = featuresList.map((v) => {
      //   return {
      //     key: v.sourceId,
      //     label: v.name,
      //     sysModuleSourceId: v.sysModuleSourceId
      //   }
      // })
      let parm = [{
        key: 'base_info',
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '使用方',
            field: 'useOrgName'
          },
          {
            label: '名称',
            field: 'user'
          },
          {
            label: '所属系统',
            field: 'sysName'
          },
          {
            label: '所属系统版本',
            field: 'sysVersionNumber'
          },
          {
            label: '类型',
            field: 'type'
          },
          {
            label: '上线时间',
            field: 'startTime'
          },
          {
            label: '上次更新时间',
            field: 'endTime'
          },
          {
            label: '状态',
            field: 'status'
          },
          {
            label: '访问地址',
            field: 'url'
          },
          {
            label: '说明',
            field: 'description'
          }]
        }
      },
      {
        key: 'app_service',
        comp: TypeItem,
        dataParm: {
          sysInstanceId: data.id
        },
        config: appServiceList({}, {sysInstances: [{key: data.id, label: data.user + ' ' + data.type}]})
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     sysInstanceId: data.id
      //   },
      //   config: sysInstanceMaintainRecordList(null, {moduleList, featuresList})
      },
      {
        key: 'sys_instance_user',
        comp: TypeItem,
        dataParm: {
          sysInstanceId: data.id
        },
        config: sysInstanceUserList()
      },
      {
        key: 'sys_version',
        comp: TypeItem,
        dataParm: {
          id: data.sysVersionId
        },
        config: sysVersionList({titleMenus: [], listMenus: []})
      },
      {
        key: 'file_info',
        comp: FileInfo,
        config: {
          filePath: fileConf.sysInstance ? fileConf.sysInstance.getPath(data) : null
        }
      }]
      if (notShowInfo && notShowInfo.length > 0) {
        parm = parm.filter(v => !notShowInfo.includes(v.key))
      }
      return parm
    }
  }
  return config
}
