<template>
  <div class="data-item-detail" v-if="initOver" :class="{'data-item-detail-row': direction === 'row', 'data-item-detail-column': direction === 'column'}">
    <div class="d-line" v-if="!isPop"></div>
    <div class="top-content" ref="title" v-if="!noTitle">
      <div class="detail-title">{{showTitle}}</div>
      <template v-if="baseInTitle">
        <component
          style="width: 40rem;"
          v-bind:is="showInfoParm.textInfo.comp"
          :data="showData"
          :dataParm="showInfoParm.textInfo.dataParm"
          :config="showInfoParm.textInfo.config">
        </component>
        <div class="detail-title-btn">
          <fm-btn
            v-for="itemM in useListMenus"
            :key="itemM.key"
            @click="clickListMenu(itemM)">{{itemM.label}}
          </fm-btn>
        </div>
      </template>
    </div>
    <div class="bottom-content" :style="bottomStyle">
      <component
        class="bottom-content-item"
        :class="{'bottom-content-item-last': index === showInfoParm.other.length - 1}"
        v-for="(item, index) in showInfoParm.other"
        :key="index"
        v-bind:is="item.comp"
        :data="showData"
        :dataParm="item.dataParm"
        :config="item.config">
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataItemDetail',
  inject: {
    parentLevel: {
      default: []
    }
  },
  provide () {
    return {
      parentLevel: this.parentLevel
    }
  },
  data () {
    return {
      infoParm: [],
      showData: null,
      baseInTitle: false,
      initOver: false,
      bottomStyle: {
        'max-height': '0px'
      },
      noTitle: true
    }
  },
  watch: {
    showData () {
      this.infoParm = []
      if (this.showData) {
        let vm = this
        this.$nextTick(async function () {
          await vm.dealInfoParm()
        })
      }
    },
    data () {
      this.showData = this.data
    }
  },
  async created () {
    if (this.direction === 'row') {
      this.baseInTitle = true
      this.noTitle = false
    } else {
      this.baseInTitle = false
      this.noTitle = true
    }
    if (this.data) {
      this.showData = this.data
    } else if (this.config.getData && this.dataParm) {
      this.showData = await this.config.getData(this.dataParm)
    }
    await this.dealInfoParm()
    this.initOver = true
  },
  props: {
    title: {
      type: String
    },
    listMenus: {
      type: Array,
      default () {
        return []
      }
    },
    direction: {
      type: String,
      default: 'column'
    },
    data: {
      type: Object
    },
    dataParm: {
      type: Object
    },
    config: {
      type: Object
    }
  },
  mounted () {
    this.bottomStyle = {
      'max-height': 'calc(100% - ' + (this.$refs.title ? this.$refs.title.clientHeight : 0) + 'px)'
    }
  },
  computed: {
    isPop () {
      return this.$store.getters.isPop
    },
    useListMenus () {
      return this.listMenus
    },
    showTitle () {
      return this.title || (this.config.getTitle ? this.config.getTitle(this.showData) : '')
    },
    showInfoParm () {
      let newData = {
        textInfo: null,
        other: []
      }
      let fileInfo = this.infoParm.filter(v => v.comp.name === 'fileInfo')
      if (fileInfo.length > 0) {
        fileInfo = fileInfo[0]
      } else {
        fileInfo = null
      }
      if (this.baseInTitle) {
        newData.textInfo = this.infoParm.filter(v => v.comp.name === 'InfoTextInfo')
        newData.textInfo = newData.textInfo.length > 0 ? newData.textInfo[0] : {}
        newData.other = this.infoParm.filter(v => v.comp.name !== 'InfoTextInfo' && v.comp.name !== 'fileInfo')
      } else {
        newData.other = this.infoParm.filter(v => v.comp.name !== 'fileInfo')
      }
      if (fileInfo) {
        newData.other.push(fileInfo)
      }
      return newData
    }
  },
  methods: {
    async dealInfoParm () {
      let data = this.config.getInfoParm(this.showData)
      let infoParm = []
      if (data && data.then) {
        infoParm = await data
      } else {
        infoParm = data
      }
      this.infoParm = infoParm.filter((v) => {
        return !(v.comp.name === 'TypeItem' && v.config && v.config.key && this.parentLevel.includes(v.config.key))
      })
    },
    handleClose () {
      this.openDetail = false
    },
    clickListMenu (itemM) {
      this.$emit('clickListMenu', itemM, this.showData)
      window.cl = this.$refs.title
    }
  }
}
</script>

<style lang="less" scoped>
.data-item-detail-row {
  .d-line {
    display: none;
  }
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .detail-title {
    margin: 1rem 1.8rem;
  }
  .bottom-content {
    border-top: 1px solid #dcdee2;
    display: flex;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    .bottom-content-item {
      flex: 1;
      border-right: 1px solid #dcdee2;
    }
    .bottom-content-item-last {
      border-right: 0;
    }
  }
}
.data-item-detail-column {
  width: 100%;
  position: relative;
  .bottom-content {
    display: block;
    border-top: 0px;
    .bottom-content-item {
      border-right: 0;
    }
  }
  .d-line {
    display: block;
    top: 0;
    bottom: 0;
    left: 0.9rem;
    background: #dcdee2;
    position: absolute;
    width: 1px;
  }
}
.top-content {
  position: relative;
}
.detail-title-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.detail-title {
  color: #2F5BEA;
  font-size: 1.3rem;
  margin-left: 1rem;
}
</style>