import TextInfo from '@/components/datainfo/TextInfo'

import {
  serverConfigurationRequest,
} from '@/api'

let formParms = [{
  type: 'input',
  label: 'cpu',
  key: 'cpu'
},
{
  type: 'input',
  label: '内存',
  key: 'ram'
},
{
  type: 'input',
  label: '磁盘',
  key: 'disk'
},
{
  type: 'input',
  label: '带宽',
  key: 'internetBandwith'
}]

export const serverConfigurationList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_configuration',
    titleConfig: {
      title: parm && parm.title ? parm.title : '性能',
      getTitleMenus: (dataList) => {
        let data = []
        if (parm && parm.titleMenus) {
          data = parm.titleMenus
        } else if (dataList && dataList.length === 0) {
          data = [{
            key: 'add',
            label: '新增'
          }]
        }
        return data
      }
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      }],
      config: serverConfiguration(),
      getTitle: (data) => {
        return data.cpu + ' ' + data.ram
      }
    },
    async getData (dataParm) {
      let data = await serverConfigurationRequest.get({serverId: dataParm.serverId, id: dataParm.id})
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '性能',
      request: serverConfigurationRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  return config
}

export const serverConfiguration = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.map((v) => {
            return {
              label: v.label,
              field: v.key
            }
          })
        }
      }]
    }
  }
  return config
}
