
import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

// import {
//   appApiList
// } from './app_api'

// import {
//   appDataList
// } from './app_data'

// import {
//   appMenuList
// } from './app_menu'

// import {
//   appFunList
// } from './app_fun'

// import {
//   appTimetaskList
// } from './app_timetask'

import {
  appVersionSoftList
} from './app_version_soft'

import fileConf from '@/syslib/file_conf'

import {
  appServiceList
} from './app_service'

import {
  appVersionConfigList
} from './app_version_config'

import store from '@/store'

import {
  appVersionRequest
  // appFunRequest
} from '@/api'


const getFormParms = function () {
  return [{
    type: 'input',
    label: '版本号',
    key: 'versionNumber',
    check: {
      required: true
    }
  },
  // {
  //   type: 'select',
  //   label: '继承版本',
  //   key: 'extendId',
  //   selectDatas: appVersionList,
  //   fmDisabled: type === 'edit'
  // },
  // {
  //   type: 'multipleChoose',
  //   label: '依赖应用',
  //   key: 'relatedAppVersionIds',
  //   selectDatas: otherAppVersionList
  // },
  {
    type: 'textarea',
    label: 'git仓库地址',
    key: 'remote'
  },
  {
    type: 'input',
    label: 'git分支',
    key: 'branch'
  },
  {
    type: 'input',
    label: '提交节点',
    key: 'node'
  },
  {
    type: 'datetimePicker',
    label: '发布时间',
    key: 'releaseTime'
  },
  {
    type: 'select',
    label: '状态',
    key: 'status',
    selectDatas: store.getters.versionStatusList,
    check: {
      required: true
    }
  },
  {
    type: 'textarea',
    label: '说明',
    key: 'description'
  }]
}

export const appVersionList = function (parm = {}) {
  let config = {
    key: parm.key || 'app_version',
    titleConfig: {
      title: parm && parm.title ? parm.title : '应用版本',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    getHisData (dataParm) {
      return {
        appId: dataParm.appId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [
        {
          key: 'task',
          label: '相关任务',
          dealFun (data, vm) {
            vm.$router.push({
              name: 'task', query: {
                type: 'app_version',
                id: data.id
              }
            })
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }  
      ],
      config: appVersion(),
      getTitle: (data) => {
        return parm && parm.getTitle ? parm.getTitle(data) : data.versionNumber + ' ' + data.status
      }
    },
    async getData (dataParm) {
      if (!dataParm || !(dataParm.id || dataParm.appId)) {
        return Promise.resolve([])
      }
      return await appVersionRequest.get(dataParm)
    },
    updateConfig: {
      title: '应用版本',
      request: appVersionRequest,
      getFormParms
    }
  }
  return config
}

export const appVersion = function ({defaultOpen} = {}) {
  let config = {
    defaultOpen: defaultOpen || false,
    async getData (dataParm) {
      if (!dataParm || !dataParm.id) {
        return Promise.resolve({})
      }
      let data = await appVersionRequest.get({id: dataParm.id})
      return data.length > 0 ? data[0] : null
    },
    getTitle: (data) => {
      return data ? data.appName + ' ' + data.versionNumber : ''
    },
    getInfoParm (data) {
      let parm = [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '版本号',
            field: 'versionNumber'
          },
          {
            label: 'git仓库地址',
            field: 'remote'
          },
          {
            label: 'git分支',
            field: 'branch'
          },
          {
            label: '提交节点',
            field: 'node'
          },
          {
            label: '发布时间',
            field: 'releaseTime'
          },
          {
            label: '状态',
            field: 'status'
          },
          {
            label: '说明',
            field: 'description'
          }]
        }
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.appVersion ? fileConf.appVersion.getPath(data) : null
        }
      },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     appVersionId: data.id
      //   },
      //   config: appApiList(null, {curryAppFuns, appDatas, curryAppDatas})
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     appVersionId: data.id
      //   },
      //   config: appTimetaskList(null, {curryAppFuns, appDatas, curryAppDatas})
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     appVersionId: data.id,
      //     relatedAppVersionIds: data.relatedAppVersionIds
      //   },
      //   config: appFunList(null, {curryAppFuns, appDatas, curryAppDatas, appApis})
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     appVersionId: data.id,
      //     relatedAppVersionIds: data.relatedAppVersionIds
      //   },
      //   config: appMenuList(null, {appApis})
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     appVersionId: data.id
      //   },
      //   config: appDataList({}, appDatas)
      // },
      // {
      //   comp: TreeInfo,
      //   dataParm: {
      //     ids: data.relatedAppVersionIds.join(',')
      //   },
      //   config: appVersionList({title: '依赖应用', titleMenus: [], listMenus: [], getTitle: (data) => {
      //     return data.appName + data.versionNumber
      //   }})
      // },
      {
        comp: TypeItem,
        dataParm: {
          appVersionId: data.id,
          appId: data.appId
        },
        config: appServiceList({}, {})
      },
      {
        comp: TypeItem,
        dataParm: {
          appVersionId: data.id
        },
        config: appVersionSoftList()
      },
      {
        comp: TypeItem,
        dataParm: {
          appVersionId: data.id,
          appId: data.appId
        },
        config: appVersionConfigList({}, {})
      }]
      return parm
    }
  }
  return config
}
