import TextInfo from '@/components/datainfo/TextInfo'

import {
  serverFirewallRequest,
} from '@/api'

let formParms = [{
  type: 'input',
  label: '端口号',
  key: 'port',
  check: {
    required: true
  }
},
{
  type: 'textarea',
  label: '入口',
  key: 'input'
},
{
  type: 'textarea',
  label: '出口',
  key: 'output'
},
{
  type: 'textarea',
  label: '说明',
  key: 'describe'
}]

export const serverFirewallList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_firewall',
    titleConfig: {
      title: parm && parm.title ? parm.title : '防火墙',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: serverFirewall(),
      getTitle: (data) => {
        return '开放端口:' + data.port
      }
    },
    async getData (dataParm) {
      let data = await serverFirewallRequest.get({serverId: dataParm.serverId, id: dataParm.id})
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '防火墙',
      request: serverFirewallRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  return config
}

export const serverFirewall = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.map((v) => {
            return {
              label: v.label,
              field: v.key
            }
          })
        }
      }]
    }
  }
  return config
}
