import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'

import {
  rsaHelper
} from '@/fmlib'

import {
  appServiceUserRequest,
  workSysPublicKeyRequest,
  userPasswordRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '用户',
  key: 'user'
},
{
  type: 'datetimePicker',
  label: '最近密码更新时间',
  key: 'passwordUpdateTime'
},
{
  type: 'select',
  label: '所属用户',
  key: 'userId',
  selectDatas: []
},
// {
//   type: 'select',
//   label: '登录方式',
//   key: 'authType',
//   selectDatas: [{key: 'rsa', label: 'rsa'}, {key: 'password', label: 'password'}]
// },
{
  type: 'textarea',
  label: '权限说明',
  key: 'authDesc'
},
{
  type: 'input',
  label: 'IP限制',
  key: 'passIp'
},
{
  type: 'textarea',
  label: '说明',
  key: 'description'
}]

export const appServiceUserList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_user',
    titleConfig: {
      title: parm && parm.title ? parm.title : '用户',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadUserList')
      return {}
    },
    getHisData (dataParm) {
      return {
        appServiceId: dataParm.appServiceId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      },
      {
        key: 'editPassword',
        label: '修改密码',
        dealFun: async function (data, vm) {
          const result = await vm.$dialog.confirm({title: '输入新密码', content: '确定', showInput: true})
          if (result) {
            let pubKey = await workSysPublicKeyRequest.get()
            let newPassword = rsaHelper.rsaEncrypt(pubKey, result)
            await appServiceUserRequest.update(data.id, Object.assign({} , data, {pwd: newPassword}))
            vm.$notify({
              title: '系统提示',
              message: '服务器用户 (' + data.userName + ') 密码修改成功',
              type: 'seccess'
            })
          }
        }
      },
      {
        key: 'getPassword',
        label: '查看密码',
        dealFun: async function (data, vm) {
          let rsaKey = await rsaHelper.genterRsaKey()
          let parm = {
            appServiceUserId: data.id,
            publicKey: rsaKey.pubkey
          }
          let pwd = await userPasswordRequest.getService(parm)
          // let pwd = rsaHelper.rsaEncrypt(pbuKeyStr, 'asdasdasd').cipher
          pwd = rsaHelper.rsaDecrypt(rsaKey.prikey, pwd)
          vm.$dialog.confirm({title: '系统提示', content: '密码: ' + pwd})
        }
      }],
      config: appServiceUser(),
      getTitle: (data) => {
        return data.user
      }
    },
    async getData (dataParm) {
      return await appServiceUserRequest.get(dataParm)
    },
    updateConfig: {
      title: '用户',
      request: appServiceUserRequest,
      getFormParms () {
        formParms[2].selectDatas = store.getters.userList
        return formParms
      }
    }
  }
  return config
}

export const appServiceUser = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.filter(v => v.key !== 'password').map((v) => {
            return {
              label: v.label,
              field: v.key === 'userId' ? 'userName' : v.key
            }
          })
        }
      }]
    }
  }
  return config
}
