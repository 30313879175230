import TextInfo from '@/components/datainfo/TextInfo'

import {
  serverNetworkRequest,
} from '@/api'

let formParms = [{
  type: 'input',
  label: 'ip',
  key: 'ip',
  check: {
    required: true
  }
},
{
  type: 'input',
  label: 'mac地址',
  key: 'mac'
},
{
  type: 'textarea',
  label: '域名',
  key: 'domainName'
}]

export const serverNetworkList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_network',
    titleConfig: {
      title: parm && parm.title ? parm.title : '网络',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: parm && parm.listMenus ? parm.listMenus : [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: serverNetwork(),
      getTitle: (data) => {
        return data.ip
      }
    },
    async getData (dataParm) {
      let data = await serverNetworkRequest.get({serverId: dataParm.serverId, id: dataParm.id})
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '网络',
      request: serverNetworkRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  return config
}

export const serverNetwork = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.map((v) => {
            return {
              label: v.label,
              field: v.key
            }
          })
        }
      }]
    }
  }
  return config
}
