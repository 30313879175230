import store from '@/store'

import fileConf from '@/syslib/file_conf'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'
import FileInfo from '@/components/datainfo/FileInfo'

import {
  serverUserList
} from './server_user'

import {
  serverNetworkList
} from './server_network'

import {
  serverFirewallList
} from './server_firewall'

import {
  serverCmdTypeList
} from './server_cmd_type'

import {
  serverConfigurationList
} from './server_configuration'

import {
  serverSoftList
} from './server_soft'

import {
  serverConfigList
} from './server_config'

import {
  appServiceList
} from './app_service'

import {
  serverRequest
} from '@/api'

let formParms = [{
  type: 'input',
  label: '名称',
  key: 'name',
  check: {
    required: true
  }
},
{
  type: 'input',
  label: '主机名称',
  key: 'hostname'
},
{
  type: 'input',
  label: '系统',
  key: 'os'
},
{
  type: 'select',
  label: '归属',
  key: 'belong',
  selectDatas: [{label: '费米', key: '费米'}, {label: '云南中石油', key: '云南中石油'}]
},
{
  type: 'select',
  label: '提供商',
  key: 'provider',
  selectDatas: [{label: '阿里云', key: '阿里云'}]
},
{
  type: 'select',
  label: '管理员',
  key: 'userId',
  selectDatas: store.getters.userList
},
{
  type: 'selct',
  label: '防火墙',
  key: 'isOpenFirewall',
  selectDatas: [{label: '开启', key: '1'}, {label: '关闭', key: '0'}]
},
{
  type: 'textarea',
  label: '说明',
  key: 'description'
}]

export const serverList = function (parm = {}) {
  let config = {
    key: parm.key || 'server',
    titleConfig: {
      title: parm && parm.title ? parm.title : '服务器',
      titleMenus: parm && parm.titleMenus ? parm.titleMenus : [{
        key: 'add',
        label: '新增'
      }]
    },
    listConfig: {
      filterFun (data, parm) {
        return data.filter((v) => {
          let need = parm ? (v.name + ' ' +  v.ips.join(',')).includes(parm) : true
          return need
        })
      },
      filterParm: [{
        type: 'input',
        placeholder: '名称',
        key: 'name'
      },
      {
        type: 'input',
        placeholder: 'IP',
        key: 'ip'
      }],
      listMenus: parm && parm.listMenus ? parm.listMenus : [
        {
          key: 'task',
          label: '相关任务',
          dealFun (data, vm) {
            vm.$router.push({
              name: 'task', query: {
                type: 'server',
                id: data.id
              }
            })
          }
        },
        {
          key: 'o_log',
          label: '操作记录',
          dealFun: (data) => {
            window.open('/#/server/server_operation_log?serverId=' + data.id)
          }
        },
        {
          key: 'cmd_log',
          label: '命令执行记录',
          dealFun: (data) => {
            window.open('/#/server/server_cmd_log?serverId=' + data.id)
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }
      ],
      config: server(parm.childParm || {}),
      getTitle: (data) => {
        return data.name
      }
    },
    async init () {
      store.dispatch('loadUserList')
      store.dispatch('loadInstallTypeList')
      return {}
    },
    async getData (dataParm) {
      let data = await serverRequest.get(dataParm)
      data.forEach(v => {
        v.isOpenFirewallText = v.isOpenFirewall ? '开启' : '关闭'
        v.isOpenFirewall = v.isOpenFirewall ? '1' : '0'
      })
      return data
    },
    updateConfig: {
      title: '服务器',
      request: serverRequest,
      getFormParms () {
        return formParms
      }
    }
  }
  config.listConfig.filterFun = parm && parm.noSearch ? null : config.listConfig.filterFun
  if (parm.openConf) {
    config.openConf = parm.openConf
  }
  return config
}

export const server = function ({defaultOpen, showCheckbox, getTitle} = {}) {
  let config = {
    showCheckbox: showCheckbox || false,
    defaultOpen: defaultOpen || false,
    async getData (dataParm) {
      if (!dataParm || !dataParm.id) {
        return Promise.resolve({})
      }
      let data = await serverRequest.get({id: dataParm.id})
      return data.length > 0 ? data[0] : null
    },
    getTitle: (data) => {
      return getTitle ? getTitle(data) : (data ? data.name : '')
    },
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: formParms.filter(v => v.key !== 'name').map((v) => {
            return {
              label: v.label,
              field: v.key === 'userId' ? 'userName' : (v.key === 'isOpenFirewall' ? 'isOpenFirewallText' : v.key)
            }
          })
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: appServiceList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverUserList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverNetworkList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverFirewallList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverCmdTypeList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverSoftList()
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverConfigList({title: '自动部署配置'})
      },
      {
        comp: TypeItem,
        dataParm: {
          serverId: data.id
        },
        config: serverConfigurationList()
      },
      {
        comp: FileInfo,
        config: {
          filePath: fileConf.server ? fileConf.server.getPath(data) : null
        }
      }]
    }
  }
  return config
}
