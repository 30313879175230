import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'

import {
  cmdTypeList
} from './cmd_type'

import {
  serverCmdTypeRequest
} from '@/api'

export const serverCmdTypeList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_cmd_type',
    titleConfig: {
      // getNoTitle: (dataList) => {
      //   return dataList.length === 0
      // },
      title: parm && parm.title ? parm.title : '命令配置',
      getTitleMenus: () => {
        let data = []
        if (parm && parm.titleMenus) {
          data = parm.titleMenus
        } else {
          data = [{
            key: 'add',
            label: '新增'
          }]
        }
        return data
      }
    },
    listConfig: {
      getListMenus: () => {
        let data = [{
          key: 'execute',
          label: '执行',
          dealFun: async (data, vm) => {
            let c = await vm.$dialog.confirm({
              title: '执行命令',
              placeholder: '请输入参数',
              okText: '执行',
              showInput: true
            })
            if (c !== false) {
              serverCmdTypeRequest.execute({serverCmdTypeId: data.id, config: c})
            }
          }
        },
        {
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
        if (parm && parm.listMenus) {
          data = parm.listMenus
        }
        return data
      },
      config: serverCmdType(parm.childParm || {}),
      getTitle: (data) => {
        return data.cmdTypeName
      }
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    async getData (dataParm) { 
      if (!dataParm.serverId && !dataParm.id) {
        return Promise.resolve([])
      }
      return await serverCmdTypeRequest.get(dataParm)
    },
    updateConfig: {
      title: '命令配置',
      request: serverCmdTypeRequest,
      getFormParms () {
        return [{
          type: 'select',
          label: '命令模版',
          key: 'cmdTypeId',
          selectDatas: store.getters.cmdTypeList,
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '配置',
          key: 'config'
        }]
      }
    }
  }
  return config
}

export const serverCmdType = function ({defaultOpen, showCheckbox}) {
  let config = {
    showCheckbox: showCheckbox || false,
    defaultOpen: defaultOpen || false,
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '命令模版',
            field: 'cmdTypeName'
          },
          {
            label: '配置',
            field: 'config'
          }]
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.cmdTypeId
        },
        config: cmdTypeList({titleMenus: [], listMenus: []})
      }]
    }
  }
  return config
}
