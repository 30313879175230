import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'

import {
  serverSoftRequest,
  serverAppInstallRequest
} from '@/api'

export const serverSoftList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_soft',
    titleConfig: {
      title: parm && parm.title ? parm.title : '软件',
      titleMenus: [{
        key: 'add',
        label: '新增'
      }]
    },
    async init () {
      store.dispatch('loadSoftList')
      return {}
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      },
      {
        key: 'install',
        label: '安装',
        dealFun: async function (data, vm) {
          const result = await vm.$dialog.confirm({title: '系统提示', content: '确定安装软件 ' + (data.softVersion || data.name) + ' 吗?'})
          if (result) {
            serverAppInstallRequest.install({
              installConfig: data.installConfig,
              serverId: data.serverId,
              installTypeId: data.softInstallTypeId
            }).then((reData) => {
              vm.$dialog.confirm({title: '安装结果:' + (reData.success ? '成功' : '失败'), content: reData.result})
            })
          }
        }
      }],
      config: serverSoft(),
      getTitle: (data) => {
        return data.softName
      }
    },
    async getData (dataParm) {
      return await serverSoftRequest.get(dataParm)
    },
    updateConfig: {
      title: '软件',
      request: serverSoftRequest,
      getFormParms () {
        return [{
          type: 'select',
          label: '软件',
          key: 'softId',
          selectDatas: store.getters.softList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: [{key: '正常', label: '正常'}, {key: '未安装', label: '未安装'}, {key: '已卸载', label: '已卸载'}]
        },
        {
          type: 'textarea',
          label: '安装配置',
          key: 'installConfig'
        },
        {
          type: 'textarea',
          label: '安装说明',
          key: 'installDesc'
        },
        {
          type: 'textarea',
          label: '更新说明',
          key: 'updateDesc'
        },
        {
          type: 'textarea',
          label: '卸载说明',
          key: 'uninstallDesc'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'description'
        }]
      }
    }
  }
  return config
}

export const serverSoft = function () {
  let config = {
    getInfoParm: () => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '软件',
            field: 'softName'
          },
          {
            label: '版本',
            field: 'softVersion'
          },
          {
            label: '安装配置',
            field: 'installConfig'
          },
          {
            label: '状态',
            field: 'status'
          },
          {
            label: '安装说明',
            field: 'installDesc'
          },
          {
            label: '更新说明',
            field: 'updateDesc'
          },
          {
            label: '卸载说明',
            field: 'uninstallDesc'
          },
          {
            label: '说明',
            field: 'description'
          }]
        }
      }]
    }
  }
  return config
}
