<template>
  <div class="data-title" @click="choose" @dblclick="dbclick" :class="{'data-title-a': active, 'data-title-column': useDirection === 'column', 'data-title-row-a': active && useDirection === 'row'}">
    <template v-if="useDirection === 'row'">
      <a class="title-text" href="javascript:;">
        {{title}}
      </a>
      <a href="javascript:;"
        :class="{'a-i-column': useDirection === 'column' && active}"
        class="fmico fmico-top-arrow df-i"
        style="transform:rotate(90deg);">
      </a>
    </template>
    <template v-else>
      <a href="javascript:;"
        :class="{'a-i-column': useDirection === 'column' && active}"
        class="fmico fmico-top-arrow df-i"
        style="transform:rotate(90deg);">
      </a>
      <a class="title-text" href="javascript:;">
        {{title}}
      </a>
      <div class="data-title-checkbox" v-if="showCheckbox" @click.stop="">
        <fm-checkbox v-model="choosed" @change="change"></fm-checkbox>
      </div>
    </template>
    <div class="data-title-menu">
      <a href="javascript:;"
        v-for="item in menus"
        :key="item.key"
        @click.stop="clickMenu(item)">
        {{item.label}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTitle',
  props: {
    title: {
      type: String
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'column'
    },
    open: {
      type: Boolean,
      default: false
    },
    menus: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      choosed: false
    }
  },
  computed: {
    useDirection () {
      return this.$store.getters.isPop ? 'row' : this.direction
    }
  },
  methods: {
    dbclick () {
      this.$emit('dbclick')
    },
    change () {
      this.$emit('checked', this.choosed)
    },
    choose () {
      this.$emit('choose')
    },
    clickMenu (item) {
      this.$emit('clickMenu', item)
    }
  }
}
</script>

<style lang="less" scoped>
.data-title-row-a {
  border-right: 4px solid #2F5BEA !important;
  background: rgba(47, 91, 234, 0.1);
}
.data-title-column {
  justify-content: flex-start !important;
  .title-text {
    margin-left: 0.7rem;
  }
}
.data-title {
  min-width: calc(15rem - 4px - 3.6rem);
  .title-text {
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    max-width: calc(100% - 1rem);
  }
  display: flex;
  border-right: 4px solid rgba(0,0,0,0);
  height: 3rem;
  color: rgba(0,0,0,0.65);
  align-items: center;
  justify-content: space-between;
  padding: 0 1.8rem;
  position: relative;
  a {
    color: rgba(0,0,0,0.65);
  }
}
.a-i-column {
  transform: rotate(180deg) !important;
}
.fmico.fmico-top-arrow.df-i {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  position: absolute;
  right: 0;
}
.data-title-a {
  color: #2F5BEA;
  a {
    color: #2F5BEA;
  }
}
.data-title-checkbox {
  margin-left: 2rem;
}
.data-title-menu {
  position: absolute;
  bottom: -8px;
  left: 1.8rem;
  font-size: 12px;
  display: none;
  a {
    color: rgba(0,0,0,0.65);
    &::before {
      content: '';
      background-color: rgba(0,0,0,0.65);
      display: inline-block;
      height: 12px;
      position: relative;
      top: 2px;
      margin: 0 5px;
      width: 1px;
    }
    &:first-child::before {display: none;}
    &:hover {
      color: #2F5BEA;
    }
  }
}
.data-title:hover {
  .data-title-menu {
    display: block;
  }
}
</style>
