import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'

import {
  serverUserList
} from './server_user'

import {
  serverNetworkList
} from './server_network'

import {
  serverConfigRequest,
  serverUserRequest,
  serverNetworkRequest
} from '@/api'

let formParms = [{
  type: 'select',
  label: '网络',
  key: 'serverNetworkId',
  selectDatas: [],
  check: {
    required: true
  }
},
{
  type: 'select',
  label: '用户',
  key: 'serverUserId',
  selectDatas: [],
  check: {
    required: true
  }
},
{
  type: 'input',
  label: '端口',
  key: 'port',
  check: {
    required: true
  }
},
{
  type: 'input',
  label: '连接方式',
  key: 'connectType',
  selectDatas: [{label: 'ssh', key: 'ssh'}],
  check: {
    required: true
  }
},
{
  type: 'input',
  label: '连接超时时长',
  key: 'connectTimeout'
},
{
  type: 'textarea',
  label: '自定义配置',
  key: 'config'
}]

export const serverConfigList = function (parm = {}) {
  let config = {
    key: parm.key || 'server_config',
    titleConfig: {
      // getNoTitle: (dataList) => {
      //   return dataList.length === 0 && (parm && parm.title)
      // },
      title: parm && parm.title ? parm.title : '服务器自动部署配置',
      getTitleMenus: (dataList) => {
        let data = []
        if (parm && parm.titleMenus) {
          data = parm.titleMenus
        } else if (dataList && dataList.length === 0) {
          data = [{
            key: 'add',
            label: '新增'
          }]
        }
        return data
      }
    },
    async init (dataParm) {
      let otherData = {
        serverUsers: [],
        serverNetworks: []
      }
      let data = await serverUserRequest.get({serverId: dataParm.serverId})
      data.forEach((item) => {
        otherData.serverUsers.push({
          key: item.id,
          label: item.username
        })
      })
      data = await serverNetworkRequest.get({serverId: dataParm.serverId})
      data.forEach((item) => {
        otherData.serverNetworks.push({
          key: item.id,
          label: item.ip
        })
      })
      return otherData
    },
    getHisData (dataParm) {
      return {
        serverId: dataParm.serverId
      }
    },
    listConfig: {
      listMenus: [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }],
      config: serverConfig(),
      getTitle: () => {
        return '服务器自动部署配置'
      }
    },
    async getData (dataParm) {
      let data = await serverConfigRequest.get({serverId: dataParm.serverId})
      data.forEach((item) => {
        item.__showNum = 0
      })
      return data
    },
    updateConfig: {
      title: '服务器自动部署配置',
      request: serverConfigRequest,
      getFormParms ({serverUsers, serverNetworks}) {
        formParms[0].selectDatas = serverNetworks
        formParms[1].selectDatas = serverUsers
        return formParms
      }
    }
  }
  return config
}

export const serverConfig = function () {
  let config = {
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '自定义配置',
            field: 'config'
          },
          {
            label: '端口',
            field: 'port'
          },
          {
            label: '连接方式',
            field: 'connectType'
          },
          {
            label: '连接超时时长',
            field: 'connectTimeout'
          }]
        }
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.serverUserId
        },
        config: serverUserList({titleMenus: [], listMenus: []})
      },
      {
        comp: TypeItem,
        dataParm: {
          id: data.serverNetworkId
        },
        config: serverNetworkList({titleMenus: [], listMenus: []})
      }]
    }
  }
  return config
}
