<template>
  <div class="info-block">
    <div class="info-item info-item-file" :class="{'info-item-f': true}" v-if="filePath">
      <div class="info-label"><span style="margin-right: 2rem;color: #2F5BEA;">相关文件</span>
        <fm-btn
          class="hover-show-btn"
          size="mini"
          @click="upload">上传
        </fm-btn>
      </div>
    </div>
    <div class="info-item info-item-file" :class="{'info-item-f': true}" :key="item.path" v-for="item in files">
      <div class="info-label">{{item.name}}</div>
      <div class="info-value">
        <a
          href="javascript:;"
          style="margin-right: 1rem;"
          class="hover-show-btn"
          size="mini"
          @click="down(item)">下载
        </a>
        <a
          href="javascript:;"
          class="hover-show-btn"
          size="mini"
          @click="del(item)">删除
        </a>
      </div>
    </div>
    <input-file-update
      :fileParm="fileParm"
      :open-dialog.sync="openDialogFile"
      @inputFileUpdateOver="loadFile"
      @handleClose="openDialogFile = false">
    </input-file-update>
  </div>
</template>

<script>
import {
  fileRequest as request
} from '@/api'

import {
  fileHepler
} from '@/fmlib'

export default {
  name: 'fileInfo',
  props: {
    config: {
      type: Object
    }
  },
  created () {
    this.filePath = this.config.filePath
    this.loadFile()
  },
  methods: {
    down (data) {
      request.download({
        path: data.path
      }).then((fileData) => {
        fileHepler.downFile(fileData, data.name)
      })
    },
    async del (data) {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除文件 ' + data.name + ' 吗?'})
      if (result) {
        request.del({'fullPath': data.path}).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '文件已删除'
          })
          this.loadFile()
        })
      }
    },
    upload () {
      this.fileParm = {
        path: this.filePath
      }
      this.openDialogFile = true
    },
    loadFile () {
      this.openDialogFile = false
      if (!this.filePath) {
        return
      }
      request.get({
        path: this.filePath
      }).then((data) => {
        this.files = data.filter(v => !v.isDirectory)
      // }).catch(() => {
      //   if (!this.filePath.includes('undefined')) {
      //     this.addDir(this.filePath)
      //   }
      })
    },
    async addDir (path) {
      // 先新建一个文件 再删除文件
      let file = new File(['add_dir'], 'add_dir.txt', {type: 'text/plain'})
      let parm = new FormData()
      parm.append('file', file)
      parm.append('path', path)
      await request.upload(parm)
      request.del({'fullPath': path + '/add_dir.txt'})
    }
  },
  data () {
    return {
      filePath: null,
      files: [],
      fileParm: {},
      openDialogFile: false
    }
  }
}
</script>

<style scoped lang="less">
.info-block {
  min-width: 15rem;
  margin-left: 1.7rem;
}
.info-item {
  min-height: 3rem;
  display: flex;
  flex-direction: column;
  margin-left: 0rem;
}
.hover-show-btn {
  display: none;
  color: rgba(0,0,0,0.65);
  font-size: 12px;
}
.info-item-file:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.info-label:hover {
  .hover-show-btn {
    display: flex !important;
  }
}
.info-item-text {
  min-height: 3rem;
  display: flex;
  flex-wrap: wrap;
  .info-item {
    background: rgba(255,255,255,0.05);
    min-height: 2rem;
    margin: 0.5rem 0 0.5rem 0;
    border-radius: 0.5rem;
    .info-label {
      margin-right: 0.2rem;
      font-size: 1rem;
    }
  }
}
.info-label {
  color: #777;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.info-value {
  color: #333;
  justify-content: center;
  display: flex;
  align-items: center;
}
.info-item-f {
  flex-direction: row !important;
}
</style>
