import store from '@/store'

import TextInfo from '@/components/datainfo/TextInfo'
import TypeItem from '@/components/datainfo/TypeItem'

import {
  restartTypeList
} from './restart_type'

import {
  appVersionConfigRequest
} from '@/api'

export const appVersionConfigList = function (parm = {}) {
  let config = {
    key: parm.key || 'app_version_config',
    titleConfig: {
      title: parm && parm.title ? parm.title : '默认部署配置',
      getTitleMenus: () => {
        let data = []
        if (parm && parm.titleMenus) {
          data = parm.titleMenus
        } else {
          data = [{
            key: 'add',
            label: '新增'
          }]
        }
        return data
      }
    },
    listConfig: {
      getListMenus: () => {
        let data = [{
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
        if (parm && parm.listMenus) {
          data = parm.listMenus
        }
        return data
      },
      config: appVersionConfig(parm.childParm || {}),
      getTitle: (data) => {
        return data.restartTypeName
      }
    },
    getHisData (dataParm) {
      return {
        appVersionId: dataParm.appVersionId
      }
    },
    async getData (dataParm) { 
      if (!dataParm.appVersionId && !dataParm.id) {
        return Promise.resolve([])
      }
      return await appVersionConfigRequest.get(dataParm)
    },
    updateConfig: {
      title: '默认部署配置',
      request: appVersionConfigRequest,
      getFormParms () {
        return [{
          type: 'textarea',
          label: '存放路径',
          key: 'uploadPkgPath'
        },
        {
          type: 'textarea',
          label: '存放文件名',
          key: 'uploadPkgName'
        },
        {
          type: 'select',
          label: '启停类型',
          key: 'restartTypeId',
          selectDatas: store.getters.restartTypeList,
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '配置',
          key: 'config'
        },
        {
          type: 'input',
          label: '启动超时',
          key: 'startTimeout'
        },
        {
          type: 'input',
          label: '停止超时',
          key: 'stopTimeout'
        },
        {
          type: 'input',
          label: '默认超时',
          key: 'timeout'
        }]
      }
    }
  }
  return config
}

export const appVersionConfig = function ({defaultOpen, showCheckbox}) {
  let config = {
    showCheckbox: showCheckbox || false,
    defaultOpen: defaultOpen || false,
    getInfoParm: (data) => {
      return [{
        comp: TextInfo,
        config: {
          infoParm: [{
            label: '存放路径',
            field: 'uploadPkgPath'
          },
          {
            label: '存放文件名',
            field: 'uploadPkgName'
          },
          {
            label: '启停类型',
            field: 'restartTypeName'
          },
          {
            label: '配置',
            field: 'config'
          },
          {
            label: '启动超时',
            field: 'startTimeout'
          },
          {
            label: '停止超时',
            field: 'stopTimeout'
          },
          {
            label: '默认超时',
            field: 'timeout'
          }]
        }
      },
      // {
      //   comp: FileInfo,
      //   config: {
      //     filePath: fileConf.appVersionConfig ? fileConf.appVersionConfig.getPath(data) : null
      //   }
      // },
      {
        comp: TypeItem,
        dataParm: {
          id: data.restartTypeId
        },
        config: restartTypeList({titleMenus: [], listMenus: []})
      }]
    }
  }
  return config
}
